<template>
	<ZyroDropdown
		:options="styleOptions"
		:current.sync="selectedStyle"
		:bold="false"
		class="select-control"
		:label="$attrs.label"
		button-size="large"
		v-on="$listeners"
	/>
</template>

<script>
import {
	mapState,
	mapMutations,
} from 'vuex';

import {
	PROPERTY_FONT_SIZE,
	PROPERTY_FONT_WEIGHT,
	PROPERTY_M_FONT_SIZE,
} from '@/constants/globalStyles';
import { convertWeightStringToNumber } from '@/utils/font';

export default {
	name: 'StyleSelectControl',
	props: {
		element: {
			type: String,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		styleOptions: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState(['website']),
		selectedStyle: {
			get() {
				const { styles } = this.website;
				const isPropertyFontWeight = this.property === PROPERTY_FONT_WEIGHT;
				const isPropertyFontSize = this.property === PROPERTY_FONT_SIZE
					|| this.property === PROPERTY_M_FONT_SIZE;
				let styleValue = styles[this.element][this.property];
				const isStyleValueInOptions = this.styleOptions
					.find((styleOption) => styleOption.value === styleValue);

				/**
				 * This is needed because selected font-size might not be in selection list
				 */
				if (isPropertyFontSize && !isStyleValueInOptions) {
					return {
						title: styleValue.replace('px', ''),
						value: styleValue,
					};
				}

				if (isPropertyFontWeight) {
					styleValue = convertWeightStringToNumber(styleValue);
				}

				if (!styleValue) {
					return { title: `Select ${this.$attrs.label} ...` };
				}

				const {
					title,
					value,
				} = this.styleOptions
					.find((styleOption) => styleOption.value === styleValue);

				return {
					title,
					value,
				};
			},
			set(style) {
				this.setStyleProperty({
					element: this.element,
					property: this.property,
					value: style.value,
				});
			},
		},
	},
	methods: { ...mapMutations(['setStyleProperty']) },
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-drawers/drawers/partials/_forms.scss';

.select-control {
	margin: 0;
}
</style>
